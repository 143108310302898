import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

const ImageListPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [info, setInfo] = useState(null)

    let getImages = async () => {
        setInfo(null)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/imageList/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            setInfo(data["data"])
        }
        else {
            console.log("ups")
        }
    }

    const handleChangeViewType = async (e, id) => {
        let view_type = e.target.value
        let obj = JSON.stringify({
            'action': 'annotate',
            'view_type': view_type,
            'image_id': id
        })
        let response = await fetch('https://breloai-backend.inesctec.pt/api/imageList/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: obj
        })
        let data = await response
    };

    const handleValidate = async (id, index) => {
        let obj = JSON.stringify({
            'action': 'validate',
            'image_id': id
        })

        let response = await fetch('https://breloai-backend.inesctec.pt/api/imageList/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: obj
        })
        let data = await response
        if (response.status === 200) {
            setInfo(info.filter((_, i) => i !== index));
        }
    };


    console.log(info)

    useEffect(() => {

    }, [info])

    return (
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Image List Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                <Button variant="outline-primary" className="addPatient-button morphingButton" onClick={() => getImages()}><i className="fa fa-check" aria-hidden="true"></i> Fetch</Button>
            </div>

            {
                info !== null ?
                    info.map((patient, index) => {
                        return (
                            <div key={patient.id} style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                                {/*<span>{patient.institutional_number}</span>*/}
                                <img alt="error" style={{ maxWidth: "15vw", maxHeight: "10vh" }} src={'https://breloai-backend.inesctec.pt' + patient.path} />
                                <Form.Group as={Row} className='mb-2'>
                                    <Col sm={8}>
                                        <Form.Select name="view_type" defaultValue={patient.view_type} onChange={(e) => handleChangeViewType(e, patient.id)}>
                                            <option value="0">---------</option>
                                            <option value="1">Anterior</option>
                                            <option value="2">Posterior</option>
                                            <option value="3">Lateral Left</option>
                                            <option value="4">Lateral Right</option>
                                            <option value="5">Left 45 Degrees</option>
                                            <option value="6">Right 45 Degrees</option>
                                        </Form.Select>
                                    </Col>

                                </Form.Group>
                                <Button variant="outline-primary" className="addPatient-button morphingButton" onClick={() => handleValidate(patient.id, index)}><i className="fa fa-check" aria-hidden="true"></i> Validate</Button>
                            </div>
                        )
                    })
                    :
                    <span>No results found</span>
            }
        </Container >

    )
}

export default withRouter(ImageListPage)