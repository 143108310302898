import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import history from '../history';
import { generatePath } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

const Header = () => {
  let { isAuthenticated, logoutUser, connected, user } = useContext(AuthContext);

  if (window.location.pathname.includes("cankado") || window.location.pathname.includes("logout")) {
    return null;
  } else return (
    <Navbar collapseOnSelect expand="lg" className='inesc-bg-md-green navbar-shadow fixed-top'>
      <Container>
        <Navbar.Brand className="px-5" onClick={() => history.push(generatePath("/"))} style={{ cursor: 'pointer' }}>
          <img alt="" src="/assets/Logo.png" width="97" height="44" className="d-inline-block" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {connected ? 
            isAuthenticated ? 
              <Nav className="ms-auto">
                <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push({ pathname: generatePath("/:username/patients", { username: user.username }) })}>Dashboard</Nav.Link>
                <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/teams"))}>Institutions</Nav.Link>
                <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push(generatePath("/catalogue"))}>Catalogue</Nav.Link>
                  {/* Dropdown for Tools */}
                  <NavDropdown title="Tools" id="tools-dropdown" className='hover-underline-animation mx-3'>
                  <NavDropdown.Item className="dropdown-item-white" onClick={() => history.push(generatePath("/breloai"))}>Retrieval</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item-white" onClick={() => history.push(generatePath("/morphing"))}>Morphing</NavDropdown.Item>
                  <NavDropdown.Item className="dropdown-item-white" onClick={() => history.push(generatePath("/camera"))}>Camera</NavDropdown.Item>
                  {
                    user.is_staff ?
                      <NavDropdown.Item className="dropdown-item-white" onClick={() => history.push(generatePath("/imageList"))}>Image List</NavDropdown.Item>
                      :
                      null
                  }
                </NavDropdown>

                <Nav.Link className='hover-underline-animation mx-3' onClick={() => history.push({ pathname: generatePath("/:username/profile", { username: user.username }) })}>Profile</Nav.Link>
                <Nav.Link id="logout" className='hover-underline-animation mx-3' onClick={() => { logoutUser(1) }}>Logout</Nav.Link>
              </Nav>
              :
              null
            :
            null
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
