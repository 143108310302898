import React, { useCallback, useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/esm/Button';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Badge from 'react-bootstrap/Badge';

import LoadingOverlay from 'react-loading-overlay';

const DashboardPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const location = useLocation()

    const [appState, setAppState] = useState({
        isLoading: true,
        stats: null,
        numPages: null,
        nextPage: null,
        prevPage: null,
    });

    const [overlayActive, setOverlayActive] = useState(false)

    const [active, setActive] = useState(location.state ? location.state.page.page : 1)
    const [manualPage, setManualPage] = useState(0)

    const [filters, setFilters] = useState(() => {
        const params = new URLSearchParams(window.location.search);
        const historyFilters = window.history.state?.filters;
        const locationFilters = location.state?.page.filters
        return (
            historyFilters || locationFilters || {
                institutional_number: params.get("institutional_number") || "",
                patient_status: params.get("patient_status") || "",
                order: params.get("order") || "3",
                surgery_type: params.get("surgery_type") || "0",
                bra_cup: params.get("bra_cup") || "",
                bra_size: params.get("bra_size") || "",
                weight: params.get("weight") || "",
                height: params.get("height") || "",
                classification: params.get("classification") || "0",
            }
        );
    });

    let page_info = location.state !== undefined ? location.state.page : { filters: filters, page: active }

    const [showToast, setShowToast] = useState(false);
    const [showToast2, setShowToast2] = useState(false);

    // Toggle function to show details
    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const updateFilter = (key, value) => {
        const updatedFilters = { ...filters, [key]: value };
        setFilters(updatedFilters);

        // Save the updated filters to the browser history
        const queryString = new URLSearchParams(updatedFilters).toString();
        const currentUrl = window.location.pathname + "?" + queryString;
        window.history.pushState({ filters: updatedFilters }, "", currentUrl);
    };

    let resetFilters = () => {
        setFilters({
            institutional_number: "",
            patient_status: "",
            order: "3",
            surgery_type: "0",
            bra_cup: "",
            bra_size: "",
            weight: "",
            height: "",
            classification: "0"
        })
    }

    // Handle back/forward navigation
    useEffect(() => {
        const handlePopState = (event) => {
            // Restore filters from history state
            const stateFilters = event.state?.filters || { institutional_number: "", patient_status: "", order: "3", surgery_type: "0", bra_cup: "", bra_size: "", weight: "", height: "", classification: "0" };
            setFilters(stateFilters);
        };

        // Listen for popstate events
        window.addEventListener("popstate", handlePopState);

        return () => {
            // Cleanup listener on component unmount
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);


    let getPatients = useCallback(
        async (activeFilters) => {
            setOverlayActive(true);
            const queryString = new URLSearchParams(activeFilters).toString();
            let response = await fetch('https://breloai-backend.inesctec.pt/api/' + user.username + '/dashboard?' + queryString + '&page=' + active, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access,
                }
            })
            let data = await response.json()
            if (response.status === 200) {
                setAppState({
                    isLoading: false,
                    stats: data['results'],
                    numPages: Math.ceil(data['count'] / 25),
                    nextPage: data['next'] === null ? null : data['next'].replace('http', 'https'),
                    prevPage: data['previous'] === null ? null : data['previous'].replace('http', 'https'),
                })
                setOverlayActive(false)
            }
        },
        [])


    let getNextPage = async (url) => {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            }
        })
        let data = await response.json()
        if (response.status === 200) {
            setAppState({
                isLoading: false,
                stats: data['results'],
                numPages: Math.ceil(data['count'] / 25),
                nextPage: data['next'] === null ? null : data['next'].replace('http', 'https'),
                prevPage: data['previous'] === null ? null : data['previous'].replace('http', 'https'),
            })
        }
    }


    let getPatientStatusText = (patient) => {
        if (patient.patient_status === null) {
            return (
                <span> Error</span>
            )
        }
        else if (patient.patient_status === 1) {
            //<Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Basic information is missing</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                    </OverlayTrigger>
                </h5>
            )
        }
        /*else if (patient.patient_status === 2) {
            return (
                <h5>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                </h5>
            )
        }
        else if (patient.patient_status === 3) {
            return (
                <h5>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge >
                </h5>
            )
        }
        else if (patient.patient_status === 4) {
            return (
                <h5>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge>
                </h5>
            )
        }*/
        else if (patient.patient_status === 5) {
            //<Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have pre surgery photo moment complete</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'orange' }} />
                    </OverlayTrigger>
                </h5>
            )
        }
        /*else if (patient.patient_status === 6) {
            return (
                <h5>
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos.</Badge>
                </h5>
            )
        }
        */
        else if (patient.patient_status === 7) {
            //<Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have annotated photos</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                    </OverlayTrigger>
                </h5 >
            )
        }
        else if (patient.patient_status === 8) {
            //<Badge bg="light" style={{ color: 'green' }}>Patient has all the required information, but isn't CINDERELLA.</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient has all the required information, but isn't CINDERELLA</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'green' }} />
                    </OverlayTrigger>
                </h5>
            )
        }
        else if (patient.patient_status === 9) {
            //<Badge bg="light" style={{ color: 'purple' }}>Patient has all the required information for CINDERELLA.</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient has all the required information for CINDERELLA</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'purple' }} />
                    </OverlayTrigger>
                </h5>
            )
        }
        else if (patient.patient_status === 10) {
            //<Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have a complete surgery</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: 'magenta' }} />
                    </OverlayTrigger>
                </h5>
            )
        }
        /*else if (patient.patient_status === 11) {
            return (
                <h5>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                    <br />
                    <Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
                </h5>
            )
        }*/
        else if (patient.patient_status === 12) {
            //<Badge bg="light" style={{ color: '#F091AD' }}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Badge>
            return (
                <h5>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Tooltip>}>
                        <i className="fas fa-circle" aria-hidden="true" style={{ color: '#F091AD' }} />
                    </OverlayTrigger>
                </h5 >
            )
        }
    }

    let items = [];
    let items_aux = []
    let limit = appState.numPages > 7 ? 7 : appState.numPages
    if (active - 1 == 0 || active - 2 == 0 || active - 3 == 0) {
        for (let i = 1; i <= limit; i++) {
            items_aux.push(i)
        }
    }
    else if (active == appState.numPages || active + 1 == appState.numPages || active + 2 == appState.numPages) {
        for (let i = limit - 1; i >= 0; i--) {
            items_aux.push(appState.numPages - i)
        }
    }
    else {
        for (let i = 1; i <= limit; i++) {
            items_aux.push(active - 4 + i)
        }
    }


    for (let number = 0; number < items_aux.length; number++) {
        let url = appState.nextPage !== null ? appState.nextPage : appState.prevPage !== null ? appState.prevPage : ""
        if (url !== "") {
            url = url.replace(/page=\d+/, "page=" + items_aux[number])
            items.push(
                <Pagination.Item key={items_aux[number]} active={items_aux[number] === active} onClick={() => { setActive(items_aux[number]); getNextPage(url) }}>
                    {items_aux[number]}
                </Pagination.Item>,
            );
        }
    }

    let updateCinderella = async (patient) => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient.id + '/update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({
                'institutional_number': patient.institutional_number,
                'birthday': patient.birthday,
                'is_cinderella': !patient.is_cinderella,
                'type': 1,
            })
        })
        let data = await response
        if (response.status === 200) {
            setShowToast2(true)
        }
    }


    let downloadDB = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/downloadDB', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response
        if (response.status === 200) {
            setShowToast(true)
        }
    }

    let downloadMasks = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/downloadMasks', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response
        if (response.status === 200) {
            setShowToast(true)
        }
    }

    let updateAllStatus = async () => {
        setOverlayActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/updateAllStatus', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response
        if (response.status === 200) {
            await getPatients(filters)
            setOverlayActive(false)
        }
    }

    // Fetch data whenever filters change
    useEffect(() => {
        const delaySearch = setTimeout(() => {
            getPatients(filters);
        }, 500)
        return () => clearTimeout(delaySearch)
    }, [filters, getPatients]);

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container>
                <ToastContainer className="mt-5" position="top-center">
                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                        <Toast.Header>
                            <strong className="me-auto">Download Successful</strong>
                        </Toast.Header>
                        <Toast.Body>
                            Go to the '/media/csvs' in the CINDERELLA NAS.
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <ToastContainer className="mt-5" position="bottom-center">
                    <Toast onClose={() => setShowToast2(false)} show={showToast2} delay={5000} autohide bg='success'>
                        <Toast.Header>
                        </Toast.Header>
                        <Toast.Body>
                            <strong className="me-auto">Patient CINDERELLA status updated!</strong>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Breadcrumb>
                    <Breadcrumb.Item active>
                        Dashboard
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Container>
                    {
                        user !== null ?
                            user.is_staff ?
                                <Container>
                                    <div className='addPatient-div'>
                                        <Button variant="outline-primary" className="addPatient-button" onClick={() => downloadDB()}><i className="fa fa-download" aria-hidden="true"></i> Download DB</Button>
                                    </div>
                                    <div className='addPatient-div'>
                                        <Button variant="outline-primary" className="addPatient-button mb-2" onClick={() => downloadMasks()}><i className="fa fa-download" aria-hidden="true"></i> Download Mask</Button>
                                    </div>
                                </Container> :
                                null
                            :
                            null
                    }
                    <Container>
                        <div className='addPatient-div'>
                            <Button variant="outline-primary" className="addPatient-button" onClick={() => history.push(generatePath("/:username/addPatient", { username: user.username }))}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Patient</Button>
                        </div>
                    </Container>
                    <div className='d-flex justify-content-start'>
                        <span className='PageTitle mt-4 mb-1'> FILTERS</span>
                    </div>
                    <Container className="mb-4 mt-2">
                        <Row className="searchContainer searchPatientContainer responsiveRow">
                            <span><h3>Filters:</h3></span>
                            <Col className={'text-center'}>
                                <input type="text" className="formPatientInput" placeholder={"Institutional Number"} style={{ width: '100%' }} onChange={(e) => updateFilter("institutional_number", e.target.value)} value={filters.institutional_number} />
                            </Col>
                            <Col className={'text-center'} >
                                <Form.Select value={filters.patient_status} onChange={(e) => updateFilter("patient_status", e.target.value)}>
                                    <option value="">--------</option>
                                    <option value="1">Red</option>
                                    <option value="10">Magenta</option>
                                    <option value="7">Blue</option>
                                    <option value="5">Orange</option>
                                    <option value="8">Green</option>
                                    <option value="12">Pink</option>
                                    <option value="9">Purple</option>
                                </Form.Select>
                            </Col>
                            <Col className={'text-center'}>
                                <Form.Select value={filters.order} onChange={(e) => updateFilter("order", e.target.value)}>
                                    <option value="3">Institutinal Number Ascending</option>
                                    <option value="4">Institutinal Number Descending</option>
                                    <option value="1">Date Descending</option>
                                    <option value="2">Date Ascending</option>
                                </Form.Select>
                            </Col>
                            <Col className={'text-center'} >
                                <Form.Select onChange={(e) => updateFilter("surgery_type", e.target.value)} value={filters.surgery_type}>
                                    <option value="0">--------</option>
                                    <option value="1">C1 - Conservative Surgery</option>
                                    <option value="2">C2 - Conservative Surgery with bilateral reduction</option>
                                    <option value="3">C3 - Conservative Surgery with LD or LICAC/TDAP flaps</option>
                                    <option value="4">C4 - Conservative Surgery with bilateral implants</option>
                                    <option value="5">M1 - Mastectomy with unilateral reconstruction with implant</option>
                                    <option value="6">M2 - Mastectomy with unilateral reconstruction with autologous flap</option>
                                    <option value="7">M3 - Mastectomy with bilateral reconstruction with implants</option>
                                    <option value="8">M4 - Mastectomy with bilateral reconstruction with autologous flaps</option>
                                    <option value="9">M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)</option>
                                    <option value="10">M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction</option>
                                    <option value="11">M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction</option>
                                    <option value="12">M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)</option>
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row className="searchContainer searchPatientContainer responsiveRow mt-2">
                            <Col className={'text-center'}>
                                <input type="text" className="formPatientInput" placeholder={"Bra Cup"} style={{ width: '100%' }} onChange={(e) => updateFilter("bra_cup", e.target.value)} value={filters.bra_cup} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" className="formPatientInput" placeholder={"Bra Size"} style={{ width: '100%' }} onChange={(e) => updateFilter("bra_size", e.target.value)} value={filters.bra_size} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" className="formPatientInput" placeholder={"Weight"} style={{ width: '100%' }} onChange={(e) => updateFilter("weight", e.target.value)} value={filters.weight} />
                            </Col>
                            <Col className={'text-center'}>
                                <input type="number" className="formPatientInput" placeholder={"Height"} style={{ width: '100%' }} onChange={(e) => updateFilter("height", e.target.value)} value={filters.height} />
                            </Col>
                        </Row>
                        <Row className="searchContainer searchPatientContainer responsiveRow mt-2">
                            <Col>
                                <Form.Select onChange={(e) => updateFilter("classification", e.target.value)} value={filters.classification}>
                                    <option value="0">Classification</option>
                                    <option value="1">Excellent</option>
                                    <option value="2">Good</option>
                                    <option value="3">Fair</option>
                                    <option value="4">Poor</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <div className='addPatient-div'>
                            <Button variant="outline-primary" className="addPatient-button" onClick={() => resetFilters()}><i className="fa fa-download" aria-hidden="true"></i> Reset Filters</Button>
                        </div>
                    </Container>

                    <div className='d-flex justify-content-start'>
                        <span className='PageTitle mt-4 mb-1'> PATIENTS</span>
                    </div>

                    <button className="btn btn-link mb-4 toggleBtn" onClick={toggleDetails}>
                        <FontAwesomeIcon icon={showDetails ? faAngleDown : faAngleRight} />
                        {showDetails ? ' Hide Details' : ' Show Details'}
                    </button>

                    <LoadingOverlay active={overlayActive} spinner>
                        {showDetails && (
                            <div className='my-3 mb-4' style={{ display: 'table', width: '100%', tableLayout: 'fixed', textAlign: 'center' }}>
                                <div className='row text-center'>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <span> Total Patients: {appState.stats.nr_total_patients}</span>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Basic information is missing</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />: {appState.stats.nr_status_red_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have a complete surgery</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'magenta' }} />: {appState.stats.nr_status_magenta_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have annotated photos</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />: {appState.stats.nr_status_blue_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have pre surgery photo moment complete</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'orange' }} />: {appState.stats.nr_status_orange_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient has all the required information, but isn't CINDERELLA</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'green' }} />: {appState.stats.nr_status_green_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: '#F091AD' }} />: {appState.stats.nr_status_pink_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-4 my-2'>
                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Patient has all the required information for CINDERELLA</Tooltip>}>
                                            <span><i className="fas fa-circle" aria-hidden="true" style={{ color: 'purple' }} />: {appState.stats.nr_status_purple_patients}</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <Button className='OutlineButton' onClick={updateAllStatus}><i className="fa-solid fa-arrows-rotate"></i></Button>
                                </div>
                            </div>
                        )}

                        <div className='table-responsive'>
                            <Table striped bordered hover className='table-responsive'>
                                <thead>
                                    <tr>
                                        <th>Institutional Number</th>
                                        <th>Status</th>
                                        <th>Uploader</th>
                                        <th>Pre-Surgery Photo</th>
                                        <th>Pre-Surgery Keypoints</th>
                                        <th>Post-Surgery Keypoints</th>
                                        <th>Objective Classification</th>
                                        <th>Subjective Classification</th>
                                        <th>CINDERELLA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        appState.stats.patients.length !== 0 ?
                                            appState.stats.patients.map((patient) => {
                                                return (
                                                    <tr key={patient.id}>
                                                        <td><a className='table-instnum-link' onClick={() => history.push({ pathname: generatePath("/patient/:id", { id: patient.id }), state: { id: patient.id, page: page_info, } })}>{patient.institutional_number}</a></td>
                                                        <td>{getPatientStatusText(patient)}</td>
                                                        <td>{patient.owner_username}</td>
                                                        <td className='text-center'>{patient.pre_surgery_favorite ? <i style={{ color: 'green', fontSize: '3vh' }} className="fa-solid fa-check"></i> : <i style={{ color: 'red', fontSize: '3vh' }} className="fa-solid fa-xmark"></i>}</td>
                                                        <td className='text-center'>{patient.pre_surgery_favorite_annotated ? <i style={{ color: 'green', fontSize: '3vh' }} className="fa-solid fa-check"></i> : <i style={{ color: 'red', fontSize: '3vh' }} className="fa-solid fa-xmark"></i>}</td>
                                                        <td className='text-center'>{patient.has_keypoints ? <i style={{ color: 'green', fontSize: '3vh' }} className="fa-solid fa-check"></i> : <i style={{ color: 'red', fontSize: '3vh' }} className="fa-solid fa-xmark"></i>}</td>
                                                        <td className='text-center'>{patient.classification !== null ? <i style={{ color: 'green', fontSize: '3vh' }} className="fa-solid fa-check"></i> : <i style={{ color: 'red', fontSize: '3vh' }} className="fa-solid fa-xmark"></i>}</td>
                                                        <td className='text-center'>{patient.subjective_classification !== null ? <i style={{ color: 'green', fontSize: '3vh' }} className="fa-solid fa-check"></i> : <i style={{ color: 'red', fontSize: '3vh' }} className="fa-solid fa-xmark"></i>}</td>
                                                        <td className='text-center'><input type="checkbox" style={{ backgroundColor: "green !important" }} defaultChecked={patient.is_cinderella} onClick={() => { updateCinderella(patient) }} /></td>
                                                    </tr>)
                                            }) :
                                            <span>No results found</span>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </LoadingOverlay>

                    <Row className='mt-3'>
                        <Col>
                            <Pagination>
                                <Pagination.First hidden={active === 1 ? true : false} onClick={() => {
                                    setActive(1); getNextPage('https://breloai-backend.inesctec.pt/api/' + user.username + "/dashboard?" + new URLSearchParams(filters).toString() + '&page=' + 1)
                                }} />
                                <Pagination.Prev hidden={active === 1 ? true : false} onClick={() => { active - 1 < 1 ? setActive(1) : setActive(active - 1); getNextPage(appState.prevPage) }} />
                                {items}
                                <Pagination.Next hidden={active === appState.numPages ? true : false} onClick={() => { active + 1 > appState.numPages ? setActive(appState.numPages) : setActive(active + 1); getNextPage(appState.nextPage) }} />
                                <Pagination.Last hidden={active === appState.numPages ? true : false} onClick={() => {
                                    setActive(appState.numPages); getNextPage('https://breloai-backend.inesctec.pt/api/' + user.username + "/dashboard?" + new URLSearchParams(filters).toString() + '&page=' + appState.numPages)
                                }} />
                            </Pagination>
                        </Col>
                        <Col>
                            <div style={{ display: 'flex', position: 'relative', float: 'right' }}>
                                <span className='mt-2'>Go to Page:</span> <Form.Control type="number" min={0} onChange={(e) => setManualPage(e.target.value)} className='mx-2' style={{ width: '5vw' }} name="foo" placeholder="Page" /> <Button variant='outline-primary' className="addPatient-button" onClick={() => {
                                    setActive(manualPage); getNextPage('https://breloai-backend.inesctec.pt/api/' + user.username + "/dashboard?" + new URLSearchParams(filters).toString() + '&page=' + manualPage)
                                }}>Go</Button>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </Container >

        )
    }
}

export default withRouter(DashboardPage)