import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import history from '../history';
import { withRouter, generatePath } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import Select from 'react-select'



const PatientProfilePage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const location = useLocation()

    console.log(location.state)

    let patient_id = location.state.id
    let page_info = location.state.page !== undefined ? location.state.page : null

    const [appState, setAppState] = useState({
        isLoading: true,
        patient: null,
    })

    const [errors, setErrors] = useState({})

    const [editCoreInfo, setEditCoreInfo] = useState(false)

    const [editHadRtBoost, setEditHadRtBoost] = useState(false)

    const [editHadRt, setEditHadRt] = useState(false)

    const [editSurgeries, setEditSurgeries] = useState([])

    const [editRadiotherapies, setEditRadiotherapies] = useState([])

    const [newSurgeryTypes, setNewSurgeryTypes] = useState([])

    const [newCancerSides, setNewCancerSides] = useState([])

    const [newProjects, setNewProjects] = useState([])

    const [defaultSurgeryValues, setDefaultSurgeryValues] = useState([])

    const [defaultCancerSideValues, setDefaultCancerSideValues] = useState([])

    const [defaultProjectsValues, setDefaultProjectsValues] = useState([])

    const [activeRtKey, setActiveRtKey] = useState(null)

    const [activeSurgeryKey, setActiveSurgeryKey] = useState("surgery1")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [hasProject, setHasProject] = useState(false)

    const options1 = [
        { value: "1", label: 'C1 - Conservative Surgery' },
        { value: "2", label: 'C2 - Conservative Surgery with bilateral reduction' },
        { value: "3", label: 'C3 - Conservative Surgery with LD or LICAC/TDAP flaps' },
        { value: "4", label: 'C4 - Conservative Surgery with bilateral implants' },
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options2 = [
        { value: "5", label: 'M1 - Mastectomy with unilateral reconstruction with implant' },
        { value: "6", label: 'M2 - Mastectomy with unilateral reconstruction with autologous flap' },
        { value: "7", label: 'M3 - Mastectomy with bilateral reconstruction with implants' },
        { value: "8", label: 'M4 - Mastectomy with bilateral reconstruction with autologous flaps' },
        { value: "9", label: 'M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)' },
        { value: "10", label: 'M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction' },
        { value: "11", label: 'M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction' },
        { value: "12", label: 'M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)' },
    ]

    const options3 = [
        { value: "13", label: 'P1 - Fat Grafting' },
        { value: "14", label: 'P2 - Scar Revision' },
        { value: "15", label: 'P3 - Nipple Reconstruction' },
        { value: "16", label: 'P4a - Unilateral Delayed Reconstruction with autolugous flap' },
        { value: "17", label: 'P4b - Bilateral Delayed Reconstruction with autolugous flap' },
        { value: "18", label: 'P5a - Unilateral Delayed Reconstruction with implant' },
        { value: "19", label: 'P5b - Bilateral Delayed Reconstruction with implant' },
        { value: "20", label: 'P6a - Unilateral Capsulectomy and implant replacement' },
        { value: "21", label: 'P6b - Bilateral Capsulectomy and implant replacement' },
        { value: "22", label: 'P7a - Uniloateral Capsulectomy and implant removal' },
        { value: "23", label: 'P7b - Biloateral Capsulectomy and implant removal' },
        { value: "24", label: 'P8a - Unilateral Reduction' },
        { value: "28", label: 'P8b - Bilateral Reduction' }
    ]

    const options4 = [
        { value: "1", label: 'Left' },
        { value: "2", label: 'Right' },
    ]

    const options5 = [
        { value: '1', label: 'CINDERELLA' },
        { value: '0', label: 'Other' }
    ]

    let getPatientInfo = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
        })
        let data = await response.json()
        if (response.status === 200) {

            data['info'] = data['info'].sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });

            setAppState({
                isLoading: false,
                patient: data,
            })

            setEditHadRtBoost(data.had_rt_boost)

            setEditHadRt(data.had_radiotherapy)

            setActiveRtKey("creation" + data.info[0].id)

            if (data.projects.length != 0) {
                getDefaultProjects(data)
                setHasProject(true)
            }
        }
    }

    let getDefaultProjects = (patient) => {
        newProjects.length = 0

        if (patient.projects != []) {
            patient.projects.map((project) => {
                options5.map((option) => {
                    if (project == option.value) {
                        defaultProjectsValues.push(option)
                        newProjects.push(option.value)
                    }
                })
            })
        }
    }

    let deletePatient = async () => {
        let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + patient_id + '/delete', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access,
            },
            body: JSON.stringify({
                'institutional_number': appState.patient.institutional_number,
            })
        })
        let data = await response
        if (response.status === 200) {
            history.push("/")
        }

    }

    let getClassificationText = (classification) => {
        if (classification === null) {
            return (
                <div>
                    <h5>Unknown</h5>
                </div>
            )
        }
        else if (classification === 1) {
            return (
                <div>
                    <h5>Excellent</h5>
                </div>
            )
        }
        else if (classification === 2) {
            return (
                <div>
                    <h5>Good</h5>
                </div>
            )
        }
        else if (classification === 3) {
            return (
                <div>
                    <h5>Fair</h5>
                </div>
            )
        }
        else if (classification === 4) {
            return (
                <div>
                    <h5>Poor</h5>
                </div>
            )
        }
    }

    let getPatientStatus = (patient_status) => {
        if (patient_status === null) {
            return (
                <span> Error</span>
            )
        }
        else if (patient_status === 1) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'red' }}>Basic information is missing.</Badge>
                </div>
            )
        }
        else if (patient_status === 5) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'orange' }}>Patient does not have pre surgery photo moment complete</Badge>
                </div>
            )
        }
        else if (patient_status === 7) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'blue' }}>Patient does not have annotated photos</Badge>
                </div>
            )
        }
        else if (patient_status === 8) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'green' }}>Patient has all the required information, but isn't CINDERELLA.</Badge>
                </div>
            )
        }
        else if (patient_status === 9) {
            return (
                <div>
                    <strong>Status:</strong><Badge bg="light" style={{ color: 'purple' }}>Patient has all the required information for CINDERELLA.</Badge>
                </div>
            )
        }
        else if (patient_status === 10) {
            return (
                <div>
                    <Badge bg="light" style={{ color: 'magenta' }}>Patient does not have a complete surgery</Badge>
                </div>
            )
        }
        else if (patient_status === 12) {
            return (
                <h2>
                    <Badge bg="light" style={{ color: '#F091AD' }}>Patient does not have post surgery photo moment complete or classification or post 1 year photos</Badge>
                </h2>
            )
        }
    }

    let updatePatient = async (type, aux, num) => {
        if (type === 1) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 1,
                    'institutional_number': appState.patient.institutional_number,
                    'projects': newProjects,
                })
            })
            let data = await response
            if (response.status === 200) {
                setEditCoreInfo(!editCoreInfo)
                getPatientInfo()
                defaultProjectsValues.length = 0
            }
            else if (response.status === 409) {
                setErrors({ "institutional_number": "A patient with that insitutional number already exists." })
            }
            else if (response.status === 410) {
                setErrors({ "institutional_number": "An insitutional number is required." })
            }
            else if (response.status === 411) {
                setErrors({ "birthday": "A birthday is required." })
            }
        }
        else if (type == 2) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 2,
                    'start_date': appState.patient.radiotherapies_info[num - 1].start_date,
                    'end_date': appState.patient.radiotherapies_info[num - 1].end_date,
                    'total_dose': appState.patient.radiotherapies_info[num - 1].total_dose,
                    'radiotherapy_boost': appState.patient.radiotherapies_info[num - 1].radiotherapy_boost,
                    'id': appState.patient.radiotherapies_info[num - 1].id,
                    'boost_dose': appState.patient.radiotherapies_info[num - 1].boost_dose,
                })
            })
            let data = await response
            if (response.status === 200) {
                setEditRadiotherapies(aux)
                getPatientInfo()
            }
        }
        else if (type == 3) {
            let response = await fetch('https://breloai-backend.inesctec.pt/api/patient/' + appState.patient.id + '/update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authTokens?.access
                },
                body: JSON.stringify({
                    'type': 3,
                    'bra_size': appState.patient.surgeries_info[num - 1].bra_size,
                    'bra_cup': appState.patient.surgeries_info[num - 1].bra_cup,
                    'weight': appState.patient.surgeries_info[num - 1].weight,
                    'surgery_date': appState.patient.surgeries_info[num - 1].date,
                    'surgery_type': newSurgeryTypes,
                    'surgical_indication': appState.patient.surgeries_info[num - 1].surgical_indication,
                    'id': appState.patient.surgeries_info[num - 1].id,
                    'height': appState.patient.surgeries_info[num - 1].height,
                    'cancer_side': newCancerSides,
                })
            })
            let data = await response
            if (response.status === 200) {
                defaultSurgeryValues.length = 0
                defaultCancerSideValues.length = 0
                setEditSurgeries(aux)
                getPatientInfo()
            }
        }
        else {
            return null
        }
    }

    useEffect(() => {
        getPatientInfo()
    }, [setAppState,])

    if (appState.isLoading === true) {
        return (
            <Container className='text-center'>
                <div style={{ marginTop: '22.5%', }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Container>
        )
    }
    else {
        return (
            <Container style={{ minWidth: "85vw" }}>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => history.push({ pathname: generatePath("/:username/patients", { username: user.username }), state: { page: page_info } })}>Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Patient {appState.patient.institutional_number} Profile
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Container className='patientPageButtons justify-content-end"'>
                    <div className='addPatient-div'>
                        <Dropdown >
                            <Dropdown.Toggle
                                variant="outline-primary"
                                className="addPatient-button patientOption-button"
                                id="dropdown-basic"
                            >
                                Add Moment
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#/action-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (patient_id) { // Check if patient_id is defined
                                            history.push({
                                                pathname: generatePath("/patient/:id/upload", { id: patient_id }),
                                                state: { patient: appState.patient, page: page_info },
                                            });
                                        } else {
                                            console.error("patient_id is undefined");
                                        }
                                    }}
                                    variant="outline-primary"
                                    disabled={!patient_id}
                                >
                                    Add Photograph Moment
                                </Dropdown.Item>

                                <Dropdown.Item
                                    href="#/action-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (patient_id) {
                                            history.push({
                                                pathname: generatePath("/patient/:id/addSurgery", { id: patient_id }),
                                                state: { patient: appState.patient, page: page_info },
                                            });
                                        } else {
                                            console.error("patient_id is undefined");
                                        }
                                    }}
                                    variant="outline-primary"
                                    disabled={!patient_id}
                                >
                                    Add Surgery Moment
                                </Dropdown.Item>

                                <Dropdown.Item
                                    href="#/action-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (patient_id) {
                                            history.push({
                                                pathname: generatePath("/patient/:id/addRadiotherapy", { id: patient_id }),
                                                state: { patient: appState.patient, page: page_info },
                                            });
                                        } else {
                                            console.error("patient_id is undefined");
                                        }
                                    }}
                                    variant="outline-primary"
                                    disabled={!patient_id}
                                >
                                    Add Radiotherapy Moment
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='addPatient-div'>
                        <Button variant="outline-primary" className="deletePatient-button my-auto" onClick={() => handleShow()}><i className="fa fa-trash" aria-hidden="true"></i> Delete Patient </Button>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Patient {patient_id}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this patient?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={() => deletePatient()}>
                                    Confirm
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Container>

                <Container>
                    <div className='d-flex justify-content-start'>
                        <span className='PageTitle mt-4 mb-1'> PATIENT INFORMATION</span>
                    </div>
                    <Container className="mb-2">
                        <Row className="responsiveRow" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Row>
                                <h4 className='mt-3'><strong>Institutional Number: </strong>{appState.patient.institutional_number}</h4>
                            </Row>

                            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}>
                                <h4>{getPatientStatus(appState.patient.status)}</h4>
                            </Row>

                            <Row>
                                <Col style={{ display: 'flex' }}>
                                    {editCoreInfo === false ?
                                        <h4 className='mt-3'><strong>Projects: </strong>{appState.patient.projects}</h4>
                                        :
                                        <div className='mt-3' style={{ display: 'inline-flex' }}>
                                            <Select
                                                isMulti
                                                options={options5}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name={"editProjects"}
                                                defaultValue={defaultProjectsValues}
                                                onChange={(e) => {
                                                    newProjects.length = 0
                                                    e.map((aux) => { newProjects.push(parseInt(aux.value)) })
                                                }
                                                }
                                            />
                                        </div>
                                    }
                                    {editCoreInfo === false ?
                                        <Button onClick={() => setEditCoreInfo(!editCoreInfo)} variant="primary" className="my-auto roundButton mt-3" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                        :
                                        <Button onClick={() => updatePatient(1, null)} variant="primary" className="my-auto roundButton mt-3"><i className="fa-solid fa-floppy-disk"></i></Button>
                                    }
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}>
                                <h4>
                                    {
                                        getPatientStatus(appState.patient.status)
                                    }
                                </h4>
                            </Col>
                        </Row>
                    </Container>

                    <div className='d-flex justify-content-start'>
                        <span className='PageTitle mt-4 mb-1'> PATIENT MOMENTS</span>
                    </div>

                    <Container className="patientContainer" style={{ minHeight: "50vh" }}>
                        <Tabs style={{ display: "block", borderBottom: "none" }} className="mb-3 responsiveRow" justify onSelect={(e) => setActiveRtKey(e)} activeKey={activeRtKey}>
                            {
                                appState.patient.info.map(information => (
                                    <Tab eventKey={information.action + information.id} title={information.action == "creation" ? "Creation" : information.action == "surgery" ? information.date + " - Surgery" :
                                        information.action == "radiotherapy" ? information.date + " - Radiotherapy" : information.action == "photograph" ? information.date + " - Photographs" : null}
                                        key={information.action + information.id}>
                                        {
                                            information.action == "creation" ?
                                                <Container style={{ minHeight: "40vh", borderBottom: "none" }}>
                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Col>
                                                            <h3 className='mt-0' style={{ color: "#444444" }}>Basic Information</h3>
                                                        </Col>
                                                        <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                            <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <h4 style={{ color: "#444444" }}><strong>Birthday: </strong> {information.date}</h4>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <h4 style={{ color: "#444444" }}><strong>Height: </strong> {information.height}</h4>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <h4 style={{ color: "#444444" }}><strong>Weight: </strong> {information.weight}</h4>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <h4 style={{ color: "#444444" }}><strong>Bra Size: </strong> {information.bra_size}</h4>
                                                    </Row>
                                                    <Row className='px-0 my-2'>
                                                        <h4 style={{ color: "#444444" }}><strong>Bra Cup: </strong> {information.bra_cup}</h4>
                                                    </Row>
                                                </Container>
                                                :
                                                information.action == "radiotherapy" ?
                                                    <Container style={{ minHeight: "40vh" }}>
                                                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Col>
                                                                <h3 className='mt-0' style={{ color: "#444444" }}>{"Radiotherapy Information"}</h3>
                                                            </Col>
                                                            <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                            </Col>
                                                        </Row>
                                                        <Row className='px-0 my-2'>
                                                            <Row>
                                                                <h4 style={{ color: "#444444" }}><strong>Radiotherapy start date: </strong> {information.date}</h4>
                                                            </Row>
                                                            <Row>
                                                                <h4 style={{ color: "#444444" }}><strong>Radiotherapy end date: </strong> {information.end_date}</h4>
                                                            </Row>
                                                            <Row>
                                                                <h4 style={{ color: "#444444" }}><strong>Total Dose: </strong> {information.total_dose}</h4>
                                                            </Row>
                                                            <Row>
                                                                <h4 style={{ color: "#444444" }}><strong>Boost Dose: </strong> {information.boost_dose}</h4>
                                                            </Row>
                                                            <h4 style={{ color: "#444444" }}><strong>Follow-up time from radiotherapy: </strong>{information.fup_rt_years && information.fup_rt_months ? information.fup_rt_years + " year and " + information.fup_rt_months + " months" :
                                                                information.fup_rt_years && !information.fup_rt_months ? information.fup_rt_years + " years" :
                                                                    information.fup_rt_months + " months"}
                                                            </h4>
                                                        </Row>
                                                    </Container>
                                                    :
                                                    information.action == "surgery" ?
                                                        <Container style={{ minHeight: "40vh" }}>
                                                            <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Col>
                                                                    <h3 className='mt-0' style={{ color: "#444444" }}>{"Surgery Information"}</h3>
                                                                </Col>
                                                                <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                </Col>
                                                            </Row>
                                                            <Row className='px-0 my-2'>
                                                                <Row>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Date: </strong> {information.date}</h4>
                                                                </Row>
                                                                <Row>
                                                                    <h4 style={{ color: "#444444" }}><strong>Follow-up time from surgery: </strong>{information.fup_surgery_years && information.fup_surgery_months ? information.fup_surgery_years + " year and " + information.fup_surgery_months + " months" :
                                                                        information.fup_surgery_years && !information.fup_surgery_months ? information.fup_surgery_years + " years" :
                                                                            information.fup_surgery_months + " months"}
                                                                    </h4>
                                                                </Row>
                                                                <Row>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery Indication: </strong>
                                                                        {
                                                                            information.surgical_indication !== null ?
                                                                                information.surgical_indication.map((type) => {
                                                                                    return <span key={parseInt(type)}>
                                                                                        {
                                                                                            parseInt(type) === 1 ? "Cancer" :
                                                                                                parseInt(type) === 2 ? "Prophylatic" :
                                                                                                    parseInt(type) === 3 ? "Improvement" :
                                                                                                        parseInt(type) === 4 ? "Benign" :
                                                                                                            null
                                                                                        }
                                                                                    </span>
                                                                                })
                                                                                :
                                                                                null
                                                                        }
                                                                    </h4>
                                                                </Row>
                                                                <Row>
                                                                    <h4 style={{ color: "#444444" }}><strong>Surgery type: </strong>
                                                                        {
                                                                            information.surgery_type.map((type) => {
                                                                                return <span key={parseInt(type)}>
                                                                                    {
                                                                                        parseInt(type) === 1 ? "C1 - Conservative Surgery" :
                                                                                            parseInt(type) === 2 ? "C2 - Conservative Surgery with bilateral reduction" :
                                                                                                parseInt(type) === 3 ? "C3 - Conservative Surgery with LD or LICAC/TDAP flaps" :
                                                                                                    parseInt(type) === 4 ? "C4 - Conservative Surgery with bilateral implants" :
                                                                                                        parseInt(type) === 5 ? "M1 - Mastectomy with unilateral reconstruction with implant" :
                                                                                                            parseInt(type) === 6 ? "M2 - Mastectomy with unilateral reconstruction with autologous flap" :
                                                                                                                parseInt(type) === 7 ? "M3 - Mastectomy with bilateral reconstruction with implants" :
                                                                                                                    parseInt(type) === 8 ? "M4 - Mastectomy with bilateral reconstruction with autologous flaps" :
                                                                                                                        parseInt(type) === 9 ? "M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)" :
                                                                                                                            parseInt(type) === 10 ? "M6 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with reduction" :
                                                                                                                                parseInt(type) === 11 ? "M7 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with reduction" :
                                                                                                                                    parseInt(type) === 12 ? "M8 - Mastectomy with unilateral reconstruction with autologous flap and contralateral symmetrization with implant (augmentation)" :
                                                                                                                                        parseInt(type) === 13 ? "P1 - Fat Grafting" :
                                                                                                                                            parseInt(type) === 14 ? "P2 - Scar Revision" :
                                                                                                                                                parseInt(type) === 15 ? "P3 - Nipple Reconstruction" :
                                                                                                                                                    parseInt(type) === 16 ? "P4a - Unilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                                        parseInt(type) === 17 ? "P4b - Bilateral Delayed Reconstruction with autolugous flap" :
                                                                                                                                                            parseInt(type) === 18 ? "P5a - Unilateral Delayed Reconstruction with implant" :
                                                                                                                                                                parseInt(type) === 19 ? "P5b - Bilateral Delayed Reconstruction with implant" :
                                                                                                                                                                    parseInt(type) === 20 ? "P6a - Unilateral Capsulectomy and implant replacement" :
                                                                                                                                                                        parseInt(type) === 21 ? "P6b - Bilateral Capsulectomy and implant replacement" :
                                                                                                                                                                            parseInt(type) === 22 ? "P7a - Uniloateral Capsulectomy and implant removal" :
                                                                                                                                                                                parseInt(type) === 23 ? "P7b - Biloateral Capsulectomy and implant removal" :
                                                                                                                                                                                    parseInt(type) === 24 ? "P8a - Unilateral Reduction" :
                                                                                                                                                                                        parseInt(type) === 28 ? "P8b - Bilateral Reduction" :
                                                                                                                                                                                            parseInt(type) === 25 ? "C5 - Margin Widening" :
                                                                                                                                                                                                parseInt(type) === 26 ? "M9a - Simple Mastectomy" :
                                                                                                                                                                                                    parseInt(type) === 27 ? "M9b - Simple Mastectomy with contralateral reduction" :
                                                                                                                                                                                                        null
                                                                                    }

                                                                                </span>
                                                                            })
                                                                        }
                                                                    </h4>
                                                                </Row>
                                                                <Row>
                                                                    <h4 style={{ color: "#444444" }}><strong>Sides: </strong> {information.cancer_sides == 1 ? "Left" :
                                                                        information.cancer_sides == 2 ? "Right" :
                                                                            information.cancer_sides == 3 ? "Bilateral" :
                                                                                null}</h4>
                                                                </Row>
                                                            </Row>
                                                        </Container>
                                                        :
                                                        information.action == "photo" ?
                                                            <Container>
                                                                <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Col>
                                                                        <h3 className='mt-0' style={{ color: "#444444" }}>{"Photos set number " + information.id}</h3>
                                                                    </Col>
                                                                    <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                        <Button onClick={() => console.log("YO")} variant="primary" className="roundButton" ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                    </Col>
                                                                </Row>
                                                                <Card>
                                                                    <Card.Header className="text-center card-header-text" as="h5">
                                                                        Classification: {getClassificationText(information.classification)}
                                                                        {information.classification_type === null ?
                                                                            null
                                                                            :
                                                                            information.classification_type === 1 ?
                                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                    <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                                                                                </OverlayTrigger>
                                                                                :
                                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                    <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                </OverlayTrigger>
                                                                        }
                                                                    </Card.Header>
                                                                    {
                                                                        information.favorite_image !== null ?
                                                                            <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + information.favorite_image} width="250" height="250" />
                                                                            :
                                                                            null
                                                                    }
                                                                    <Card.Body>
                                                                        <div className='my-2 text-center'>
                                                                            {
                                                                                (information.pre_surgery) ?
                                                                                    <span>Pre-surgery</span> :
                                                                                    (information.post_surgery && information.fup_years && information.fup_months) ?
                                                                                        <span>Post-surgery | Follow-up time: {information.fup_years} years and {information.fup_months} months</span> :
                                                                                        (information.post_surgery && information.fup_years && !information.fup_months) ?
                                                                                            <span>Post-surgery | Follow-up time: {information.fup_years} years</span> :
                                                                                            (information.post_surgery && !information.fup_years && information.fup_months) ?
                                                                                                <span>Post-surgery | Follow-up time: {information.fup_months} months</span> :
                                                                                                <span>Post-surgery | Follow-up time: less than 1 month</span>
                                                                            }
                                                                        </div>
                                                                        <div className='text-center'>
                                                                            <Button className='FilledButton' onClick={() => history.push({ pathname: generatePath("/patient/:id/photos/:date", { id: appState.patient.id, date: information.date }), state: { patient_id: appState.patient.id, date: information.date, page: page_info, institutional_number: appState.patient.institutional_number, pre_surgery: information.pre_surgery, patient: appState.patient } })} ><i className="fa fa-images" aria-hidden="true" ></i> {information.date} ({information.num_photos}) </Button>
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Container>
                                                            :
                                                            information.action == "photograph" ?
                                                                <Container style={{ minHeight: "40vh" }}>
                                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Col>
                                                                            <h3 className='mt-0' style={{ color: "#444444" }}>{"Photograph Moment Info"}</h3>
                                                                        </Col>
                                                                        <Col style={{ display: 'flex', justifyContent: 'end' }}>
                                                                            <Button onClick={() => history.push({ pathname: generatePath("/patient/:id/edit/:id2", { id: patient_id, id2: information.id }), state: { info: information, page: page_info, patient_id: patient_id } })} variant="primary" className="roundButton " ><i className="fas fa-edit" aria-hidden="true"></i></Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Col className='px-0 my-2'>
                                                                        <Row>
                                                                            <h4 style={{ color: "#444444" }}><strong>Acquisition Date: </strong> {information.date}</h4>
                                                                        </Row>
                                                                        <Row>
                                                                            <h4 style={{ color: "#444444" }}><strong>Height: </strong> {information.height}</h4>
                                                                        </Row>
                                                                        <Row>
                                                                            <h4 style={{ color: "#444444" }}><strong>Weight: </strong>{information.weight}</h4>
                                                                        </Row>
                                                                        <Row>
                                                                            <h4 style={{ color: "#444444" }}><strong>Bra Size: </strong> {information.bra_size}</h4>
                                                                        </Row>
                                                                        <Row>
                                                                            <h4 style={{ color: "#444444" }}><strong>Bra Cup: </strong>{information.bra_cup}</h4>
                                                                        </Row>
                                                                    </Col>
                                                                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Card className="patientCard" style={{ width: "13vw" }}>
                                                                                <Card.Header className="text-center card-header-text" as="h5">
                                                                                    Subjective Classification: {getClassificationText(information.classification_subjective)}
                                                                                    {information.classification_subjective === null ?
                                                                                        null
                                                                                        :
                                                                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Subjective Classification</Tooltip>}>
                                                                                            <i className="fas fa-circle" aria-hidden="true" style={{ color: 'red' }} />
                                                                                        </OverlayTrigger>

                                                                                    }
                                                                                    Objective Classification: {getClassificationText(information.classification_objective)}
                                                                                    {information.classification_objective === null ?
                                                                                        null
                                                                                        :
                                                                                        <OverlayTrigger placement='bottom' overlay={<Tooltip id={`tooltip-bottom`}>Objective Classification</Tooltip>}>
                                                                                            <i className="fas fa-circle" aria-hidden="true" style={{ color: 'blue' }} />
                                                                                        </OverlayTrigger>
                                                                                    }
                                                                                </Card.Header>
                                                                                {
                                                                                    information.favorite_image_path !== null ?
                                                                                        <Card.Img variant="top" src={'https://breloai-backend.inesctec.pt' + information.favorite_image_path} width="150" height="150" />
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <Card.Body>
                                                                                    <div className='my-2 text-center'>
                                                                                        {
                                                                                            (information.pre_or_post == "pre") ?
                                                                                                <span>Pre-surgery</span> :
                                                                                                (information.pre_or_post == "post" && information.fup_years && information.fup_months) ?
                                                                                                    <span>Post-surgery | Follow-up time: {information.fup_years} years and {information.fup_months} months</span> :
                                                                                                    (information.pre_or_post == "post" && information.fup_years && !information.fup_months) ?
                                                                                                        <span>Post-surgery | Follow-up time: {information.fup_years} years</span> :
                                                                                                        (information.pre_or_post == "post" && !information.fup_years && information.fup_months) ?
                                                                                                            <span>Post-surgery | Follow-up time: {information.fup_months} months</span> :
                                                                                                            <span>Post-surgery | Follow-up time: less than 1 month</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='text-center'>
                                                                                        <Button className='FilledButton' onClick={() => history.push({ pathname: generatePath("/patient/:id/photographs/:date", { id: appState.patient.id, date: information.date }), state: { patient_id: appState.patient.id, date: information.date, page: page_info, institutional_number: appState.patient.institutional_number, pre_surgery: information.pre_or_post == "pre" ? true : false, fup_years: information.fup_years, fup_months: information.fup_months, patient: appState.patient } })} ><i className="fa fa-images" aria-hidden="true" ></i> {information.date} ({information.num_photos}) </Button>
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                                :
                                                                null
                                        }

                                    </Tab>
                                ))
                            }

                        </Tabs>
                    </Container>
                </Container>
            </Container>
        )
    }
}
export default withRouter(PatientProfilePage)