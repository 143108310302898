import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext';
import { withRouter, generatePath } from "react-router-dom";
import history from '../history';

import Button from 'react-bootstrap/esm/Button';
import Container from 'react-bootstrap/esm/Container';
import { Card, Row, Col } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Collapsible from 'react-collapsible';
import { ListManager } from 'react-beautiful-dnd-grid';
import LoadingOverlay from 'react-loading-overlay';
import Switch from "react-switch";

const MorphingPage = () => {
    let { isAuthenticated, authTokens, user, url } = useContext(AuthContext)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isActive, setIsActive] = useState(false)

    const [toggleMorphing, setToggleMorphing] = useState(false)
    const handleToggleMorphing = () => {
        setToggleMorphing(!toggleMorphing)
    }

    const [showToast, setShowToast] = useState(false);

    const [fullscreenImage, setFullscreenImage] = useState(null);

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    const [query_patient_image, setQuery_patient_image] = useState(null)
    const [template_patient_image, setTemplate_patient_image] = useState(null)
    const [morphed_image_helena_inpainting, setMorphed_image_helena_inpainting] = useState(null)
    const [morphed_image_helena_inpainting_v2, setMorphed_image_helena_inpainting_v2] = useState(null)
    const [morphed_image_helena_disentanglement, setMorphed_image_helena_disentanglement] = useState(null)
    const [morphed_image_helena_segmentation, setMorphed_image_helena_segmentation] = useState(null)
    const [morphed_image_helena_segmentation_v2, setMorphed_image_helena_segmentation_v2] = useState(null)
    const [morphed_image_nuno, setMorphed_image_nuno] = useState(null)
    const [catalogue_id, setCatalogue_id] = useState(null)
    const [catalogue_type, setCatalogue_type] = useState(null)
    const [catalogue_number, setCatalogue_number] = useState(null)
    const [max_number, setMaxNumber] = useState(0)
    const [morphing_type, setMorphingType] = useState(null)

    console.log(morphing_type)

    const [end, setEnd] = useState(false)

    let sides = ['left', 'left', 'right', 'right', 'right', 'right', 'left', 'right', 'right', 'left', 'right', 'right', 'left', 'left', 'right', 'right', 'left', 'right', 'left', 'left', 'right', 'right', 'right', 'left', 'right', 'left', 'left', 'left', 'left', 'right', 'left', 'right']
    let classifications = [1, 3, 2, 3, 2, 3, 2, 3, 2, 3, 1, 3, 2, 4, 2, 4, 2, 3, 2, 3, 1, 3, 2, 3, 2, 3, 2, 3, 2, 3, 2, 3]
    let surgery_types = [1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 9, 5, 1, 1, 2, 2, 2, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1]
    /*let getImages = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
        })
        let data = await response.json()
        if (response.status === 200) {
            console.log(data)
            setQuery_patient_image(data['query_patient_image'])
            setTemplate_patient_image(data['template_patient_image'])
            setMorphed_image_helena_inpainting(data['inpainting_morphed_img'])
            setMorphed_image_helena_disentanglement(data['disentanglement_morphed_img'])
            setMorphed_image_nuno(data['nuno_morphed_img'])
        }
        else {
            console.log("ups")
        }
        setIsActive(false)
    }*/

    let getImages = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({ "action": "catalogue", "catalogue_id": catalogue_id, "catalogue_type": catalogue_type, "catalogue_number": catalogue_number })
        })
        let data = await response.json()
        if (response.status === 200) {
            setQuery_patient_image(data['query_image_path'])
            setTemplate_patient_image(data['retrieved_image_path'])
            setMorphed_image_helena_inpainting(data['impainting_image_path'])
            setMorphed_image_helena_inpainting_v2(data['impainting_v2_image_path'])
            setMorphed_image_helena_disentanglement(data['disentanglement_image_path'])
            setMorphed_image_helena_segmentation(data['segmentation_image_path'])
            setMorphed_image_helena_segmentation_v2(data['segmentation_v2_image_path'])
            setMorphed_image_nuno(data['nuno_morph_image_path'])
            setCatalogue_id(data['catalogue_id'])
            setCatalogue_type(data['catalogue_type'])
            setCatalogue_number(data['catalogue_number'])
            if (data['catalogue_number'] > max_number) {
                setMaxNumber(data['catalogue_number'])
            }
        }
        else if (response.status === 202) {
            setQuery_patient_image(null)
            setTemplate_patient_image(null)
            setMorphed_image_helena_inpainting(null)
            setMorphed_image_helena_inpainting_v2(null)
            setMorphed_image_helena_disentanglement(null)
            setMorphed_image_helena_segmentation(null)
            setMorphed_image_helena_segmentation_v2(null)
            setMorphed_image_nuno(null)
            setCatalogue_id(null)
            setCatalogue_type(null)

            setEnd(true)
        }
        else {
            console.log("ups")
        }
        await new Promise(r => setTimeout(r, 300));
        setIsActive(false)
    }

    let handleSubmit = async (choice) => {

        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({ "action": "update_catalogue", "choice": choice, "catalogue_id": catalogue_id, "catalogue_type": catalogue_type, "catalogue_number": catalogue_number })
        })
        if (response.status === 200) {
            getImages()
        }
        else if (response.status === 202) {
            setQuery_patient_image(null)
            setTemplate_patient_image(null)
            setMorphed_image_helena_inpainting(null)
            setMorphed_image_helena_inpainting_v2(null)
            setMorphed_image_helena_disentanglement(null)
            setMorphed_image_helena_segmentation(null)
            setMorphed_image_helena_segmentation_v2(null)
            setMorphed_image_nuno(null)
            setCatalogue_id(null)
            setCatalogue_type(null)
        }
    }

    let previousCatalogue = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({ "action": "retrieve_catalogue", "catalogue_number": catalogue_number - 1 })
        })
        let data = await response.json()
        if (response.status === 200) {
            setQuery_patient_image(data['query_image_path'])
            setTemplate_patient_image(data['retrieved_image_path'])
            setMorphed_image_helena_inpainting(data['impainting_image_path'])
            setMorphed_image_helena_inpainting_v2(data['impainting_v2_image_path'])
            setMorphed_image_helena_disentanglement(data['disentanglement_image_path'])
            setMorphed_image_helena_segmentation(data['segmentation_image_path'])
            setMorphed_image_helena_segmentation_v2(data['segmentation_v2_image_path'])
            setMorphed_image_nuno(data['nuno_morph_image_path'])
            setCatalogue_id(data['catalogue_id'])
            setCatalogue_type(data['catalogue_type'])
            setCatalogue_number(data['catalogue_number'])
            setMorphingType(data['morphing_type'])
        }
        await new Promise(r => setTimeout(r, 300));
        setIsActive(false)
    }

    let nextCatalogue = async () => {
        setIsActive(true)
        let response = await fetch('https://breloai-backend.inesctec.pt/api/compareMorphing/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authTokens?.access
            },
            body: JSON.stringify({ "action": "retrieve_catalogue", "catalogue_number": catalogue_number + 1 })
        })
        let data = await response.json()
        if (response.status === 200) {
            setQuery_patient_image(data['query_image_path'])
            setTemplate_patient_image(data['retrieved_image_path'])
            setMorphed_image_helena_inpainting(data['impainting_image_path'])
            setMorphed_image_helena_inpainting_v2(data['impainting_v2_image_path'])
            setMorphed_image_helena_disentanglement(data['disentanglement_image_path'])
            setMorphed_image_helena_segmentation(data['segmentation_image_path'])
            setMorphed_image_helena_segmentation_v2(data['segmentation_v2_image_path'])
            setMorphed_image_nuno(data['nuno_morph_image_path'])
            setCatalogue_id(data['catalogue_id'])
            setCatalogue_type(data['catalogue_type'])
            setCatalogue_number(data['catalogue_number'])
            setMorphingType(data['morphing_type'])
        }
        await new Promise(r => setTimeout(r, 300));
        setIsActive(false)
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <Container style={{ minWidth: '100vw' }}>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(generatePath("/"))}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Morphing Page
                </Breadcrumb.Item>
            </Breadcrumb>

            <ToastContainer className="mt-5" position="top-center">
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='success'>
                    <Toast.Header>
                        <strong className="me-auto">Download Successful</strong>
                    </Toast.Header>
                    <Toast.Body>
                        Go to the '/media/csvs' in the CINDERELLA NAS.
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {
                user !== null ?
                    user.is_staff ?
                        <Container>
                            <div className='addPatient-div'>
                                <Button variant="outline-primary" className="addPatient-button" onClick={() => console.log("yo")}><i className="fa fa-download" aria-hidden="true"></i> Download Morphing Data</Button>
                            </div>
                        </Container> :
                        null
                    :
                    null
            }

            {
                end ?

                    <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                        <h2>There are no more catalogues. Thank you for your help!</h2>
                    </div>

                    :
                    <div>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>{catalogue_number}/32</span>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>Breast to change: {sides[catalogue_number - 1] == "left" ? "Left" : "Right"}</span>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>Template Image Classification: {classifications[catalogue_number - 1] == 1 ? "Excellent" : classifications[catalogue_number - 1] == 2 ? "Good" : classifications[catalogue_number - 1] == 3 ? "Fair" : "Poor"}</span>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>Surgery Type: {surgery_types[catalogue_number - 1] == 1 ? "C1 - Conservative Surgery" : surgery_types[catalogue_number - 1] == 2 ? "C2 - Conservative Surgery with bilateral reduction" : surgery_types[catalogue_number - 1] == 5 ? "M1 - Mastectomy with unilateral reconstruction with implant" : surgery_types[catalogue_number - 1] == 9 ? "M5 - Mastectomy with unilateral reconstruction with implant and contralateral symmetrization with implant (augmentation)" : null}</span>
                        <div className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                            {
                                <Button disabled={catalogue_number == 1 ? true : false} variant="outline-primary morphingButton" className="addPatient-button" onClick={() => previousCatalogue()}><i class="fa-solid fa-arrow-left" aria-hidden="true"></i> Previous </Button>
                            }
                            <Card className="text-center queryCardMorphing mx-4" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Query Patient</Card.Header>
                                <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={query_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + query_patient_image} width="250" height="250" />
                            </Card>

                            <Card className="text-center queryCardMorphing" style={{ width: '20vw' }}>
                                <Card.Header style={{ backgroundColor: "white" }}>Template Patient</Card.Header>
                                <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={template_patient_image === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + template_patient_image} width="250" height="250" />
                            </Card>
                            {
                                <Button disabled={max_number == catalogue_number ? true : false} variant="outline-primary morphingButton" className="addPatient-button" onClick={() => nextCatalogue()}><i class="fa-solid fa-arrow-right" aria-hidden="true"></i> Next </Button>
                            }
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', margin: '2vw' }}>
                            <span style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginRight: '1vw' }}>If none of the algorithms is suitable press the following button!</span>
                            <Button variant="outline-primary morphingButton" className={morphing_type == 7 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(7)}><i className="fa fa-check" aria-hidden="true"></i> None </Button>
                        </div>
                        <LoadingOverlay active={isActive} spinner>
                            <Row className="responsiveRow">
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 1</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_disentanglement === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_helena_disentanglement} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 2 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(2)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 2</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_inpainting === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_helena_inpainting} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 3 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(3)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 3</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_segmentation === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_helena_segmentation} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 4 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(4)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="responsiveRow">
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 4</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_nuno === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_nuno} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 1 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(1)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 5</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_inpainting_v2 === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_helena_inpainting_v2} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 5 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(5)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Card className="text-center cardMorphing" style={{ width: '20vw' }}>
                                            <Card.Header style={{ backgroundColor: "white" }}>Algorithm 6</Card.Header>
                                            <Card.Img onClick={(e) => openFullscreen(e.target.src)} variant="top" src={morphed_image_helena_segmentation_v2 === null ? 'https://breloai-backend.inesctec.pt/media/default.jpeg' : 'https://breloai-backend.inesctec.pt' + morphed_image_helena_segmentation_v2} width="250" height="250" />
                                        </Card>
                                    </Row>
                                    <Row className='mb-4' style={{ display: "flex", alignItems: "center", justifyContent: 'center', }}>
                                        <Button variant="outline-primary morphingButton" className={morphing_type == 6 ? "FilledButton" : "addPatient-button"} onClick={() => handleSubmit(6)}><i className="fa fa-check" aria-hidden="true"></i> Select</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </LoadingOverlay>

                        {fullscreenImage && (
                            <div
                                className="fullscreen-overlay"
                                onClick={closeFullscreen}
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100vw",
                                    height: "100vh",
                                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: 9999,
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    src={fullscreenImage}
                                    alt="Fullscreen View"
                                    style={{
                                        maxWidth: "90%",
                                        maxHeight: "90%",
                                        objectFit: "contain",
                                    }}
                                />
                            </div>
                        )}
                    </div >
            }
        </Container >

    )
}

export default withRouter(MorphingPage)